import React from 'react';
import { Link } from 'react-router-dom';

import { FaWhatsapp } from 'react-icons/fa';
import logoImg from '../../assets/Logo.svg';
import whatsAppImg from '../../assets/whatsapp.svg';

import { ButtonMore, Content, Container, Background, Logo } from './styles';

const LandingPage: React.FC = () => {
  return (
    <Container>
      <Content>
        <Logo>
          <img src={logoImg} alt="TR Tecnologias" />
        </Logo>

        <span>
          <h1>Aplicativos, sites e muito mais</h1>
          <h3>
            Empresa de automação comercial sempre inovando e trazendo tudo de
            melhor para seus clientes.
          </h3>
          <ButtonMore>
            <Link to="/productsandservices">
              <button type="submit">Saiba mais</button>
            </Link>
          </ButtonMore>
        </span>
      </Content>

      <Background />

      <a target="_blank" href="https://wa.me/+5582981245920">
        <img src={whatsAppImg} alt="Whatsapp" />
      </a>
    </Container>
  );
};

export default LandingPage;
