import React, { useState } from 'react';
import { Link } from 'react-router-dom';

import Carousel, { Dots } from '@brainhubeu/react-carousel';
// import Icon from 'react-fa';
import '@brainhubeu/react-carousel/lib/style.css';

import {
  Header,
  Back,
  CarouselPages,
  TextCarousel,
  SubTextCarousel,
  ContainerText,
  ButtonMore,
  DotsStyle,
  ContainerDot,
  ContainerCarousel,
  TextPromotinal,
  ContainerTextPromotional,
  Footer,
  Brand,
  ContainerBrand,
  ImgLogo,
  Phone,
  Email,
  ContainerFaleConosco,
  ContainerImageFooter,
  SocialMidia,
  ContainerSocialMidia,
} from './styles';

import logoImg from '../../assets/Logo.svg';
import arrowLeft from '../../assets/arrowLeft.svg';
import devSite from '../../assets/devSite.svg';
import arSerasa from '../../assets/arserasa.svg';
import phoneIcon from '../../assets/phone.svg';
import emailIcon from '../../assets/email.svg';
import facebookIcon from '../../assets/facebookIcon.svg';
import instagramIcon from '../../assets/instagramIcon.svg';
import youtubeIcon from '../../assets/youtubeIcon.svg';
import manutencao from '../../assets/manutencao.svg';
import drawer from '../../assets/drawer.svg';

const ProductsAndServices: React.FC = () => {
  const [value, setValue] = useState(0);

  function onChange(v: any) {
    setValue(v);
  }

  return (
    <>
      <Header>
        <img src={logoImg} alt="TR Tecnologias" />
        <Back>
          <Link to="/">
            <img src={arrowLeft} alt="back" />
          </Link>
        </Back>
      </Header>
      <ContainerCarousel>
        <Carousel
          autoPlay={4000}
          infinite
          value={value}
          onChange={onChange}
          arrows
        >
          <CarouselPages>
            <img src={devSite} alt="TR Tecnologias" />
            <ContainerText>
              <TextCarousel>
                <h1>Desenvolvimento de softwares</h1>
              </TextCarousel>
              <SubTextCarousel>
                <span>
                  Sites personalizados e totalmente de acordo com seu gosto! Não
                  perca tempo, venha aproveitar.
                </span>
              </SubTextCarousel>
              <ButtonMore>
                <Link to="/productsandservices">
                  <button type="submit">Venha conferir</button>
                </Link>
              </ButtonMore>
            </ContainerText>
          </CarouselPages>
          <CarouselPages>
            <img src={arSerasa} alt="TR Tecnologias" />
            <ContainerText>
              <TextCarousel>
                <h1>Certificado Digital</h1>
              </TextCarousel>
              <SubTextCarousel>
                <span>
                  Venha conferir e aproveite e peça seu certificado digital!
                  Criamos para você e entregamos em suas proprias mãos.
                </span>
              </SubTextCarousel>
              <ButtonMore>
                <Link to="/productsandservices">
                  <button type="submit">Venha conferir</button>
                </Link>
              </ButtonMore>
            </ContainerText>
          </CarouselPages>

          <CarouselPages>
            <img src={manutencao} alt="TR Tecnologias" />
            <ContainerText>
              <TextCarousel>
                <h1>Manutenção e suporte</h1>
              </TextCarousel>
              <SubTextCarousel>
                <span>
                  Deu problema no seu notebook ou computador? Bem-vindo (a),
                  você está no lugar certo! Quer ter um suporte de qualidade,
                  vem conferir tudo!
                </span>
              </SubTextCarousel>
              <ButtonMore>
                <Link to="/productsandservices">
                  <button type="submit">Venha conferir</button>
                </Link>
              </ButtonMore>
            </ContainerText>
          </CarouselPages>

          <CarouselPages>
            <img src={drawer} alt="TR Tecnologias" />
            <ContainerText>
              <TextCarousel>
                <h1>Logomarcas e caricatuas</h1>
              </TextCarousel>
              <SubTextCarousel>
                <span>
                  Quer criar um negocio e precisa de uma logomarca? A TR
                  Tecnologias está aqui pra te salvar. E venha conferir nossas
                  caricaturas ;)
                </span>
              </SubTextCarousel>
              <ButtonMore>
                <Link to="/productsandservices">
                  <button type="submit">Venha conferir</button>
                </Link>
              </ButtonMore>
            </ContainerText>
          </CarouselPages>
        </Carousel>
      </ContainerCarousel>
      <ContainerDot>
        <Dots
          value={value}
          onChange={onChange}
          thumbnails={[
            <DotsStyle />,
            <DotsStyle />,
            <DotsStyle />,
            <DotsStyle />,
          ]}
        />
      </ContainerDot>
      <ContainerTextPromotional>
        <TextPromotinal>
          <p>
            Não quer falar via whatsapp ou ligação, então manda um email para
            <strong> comercial@trtecnologias.com.br</strong>
          </p>
        </TextPromotinal>
      </ContainerTextPromotional>

      <Footer>
        <ImgLogo>
          <ContainerImageFooter>
            <img src={logoImg} alt="TR Tecnologias" />
            <h4>
              Vamos te mostrar o verdadeiro poder da tecnologia e como sabemos
              explora-la ao máximo para que você cliente fique satisfeito!
            </h4>
          </ContainerImageFooter>

          <ContainerFaleConosco>
            <p>Fale conosco</p>
            <Phone>
              <img src={phoneIcon} alt="phone" />
              <span>(82) 98124-5920</span>
            </Phone>
            <Email>
              <img src={emailIcon} alt="phone" />
              <span>comercial@trtecnologias.com.br</span>
            </Email>
          </ContainerFaleConosco>
          <ContainerSocialMidia>
            <p>Comunidade TR Tecnologias</p>
            <SocialMidia>
              <img src={facebookIcon} alt="facebook" />
              <img src={instagramIcon} alt="facebook" />
              <img src={youtubeIcon} alt="facebook" />
            </SocialMidia>
          </ContainerSocialMidia>
        </ImgLogo>
      </Footer>

      <ContainerBrand>
        <Brand>
          <p>TR Tecnologias - CNPJ. 27.159.505/0001-50</p>
          <p>© Todos os direitos reservados 2020</p>
        </Brand>
      </ContainerBrand>
    </>
  );
};

export default ProductsAndServices;
