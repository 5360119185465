import styled from 'styled-components';

export const Header = styled.header`
  display: flex;
  align-items: center;
  flex-direction: 'row';
  margin-left: 56px;
  margin-right: 56px;
  margin-top: 20px;
  /* position: fixed; */
`;

export const Back = styled.div`
  display: flex;
  margin-left: 20px;
  flex-direction: 'row';
`;

export const CarouselPages = styled.div`
  display: flex;
  flex: 1;
  margin-top: 80px;
  /* flex-direction: row; */
  /* margin-left: 70px;
  margin-right: 70px; */
  width: 100%;

  flex-wrap: 1;
`;

export const TextCarousel = styled.div`
  display: flex;
  margin-top: 20px;
  h1 {
    color: #50c9c6;
  }
`;

export const SubTextCarousel = styled.div`
  width: 300px;
  height: 52px;
  margin-top: 16px;
  /* background: #000000; */

  span {
    color: #50c9c6;
  }
`;

export const ContainerText = styled.div`
  align-items: center;
  justify-content: center;
  display: flex;
  flex-direction: column;
  margin-left: 60px;
`;

export const ButtonMore = styled.span`
  button {
    color: #ffffff;
    background: #13b5a0;
    font-size: 16px;
    width: 242px;
    height: 48px;
    margin-top: 15px;
    border-radius: 15px;
    border: 0;
    cursor: pointer;

    &:hover {
      background: #089a87;
    }
  }
`;

export const DotsStyle = styled.circle`
  height: 14px;
  width: 14px;
  border-radius: 7px;
  background-color: #086a5a;
  display: inline-block;
`;

export const ContainerDot = styled.span`
  background: #ffffff;
  height: 10px;
  width: 10px;

  .BrainhubCarousel__thumbnail {
    margin-top: 50px;
    background: #ffffff;
  }
`;

export const ContainerCarousel = styled.div`
  .BrainhubCarousel__arrows {
    background-color: #12a189;
    border-radius: 50%;
    margin-left: 56px;
    margin-right: 56px;

    &:hover {
      background-color: #0b7e6b;
    }
  }
`;

export const TextPromotinal = styled.div`
  display: inline-block;
  justify-content: center;
  width: 35%;
  margin-top: 25px;
  p {
    text-align: center;
    color: #076a68;
    font-family: sans-serif;
  }
`;

export const ContainerTextPromotional = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
  margin-top: 20px;
`;

export const Footer = styled.footer`
  display: flex;
  background-color: #eef0f3;
  flex-direction: column;
  width: 100%;
  height: 250px;
  margin-top: 50px;
`;

export const ContainerBrand = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 40px;
`;

export const Brand = styled.div`
  display: flex;
  width: 100%;
  height: 40px;
  background-color: #ffffff;
  justify-content: space-between;
  align-items: center;
  margin-left: 10px;
  margin-right: 10px;

  p {
    font-family: 'Noto Sans Bengali';
    font-size: 14px;
    font-weight: bold;
    color: #367d71;
  }
`;

export const ImgLogo = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 70px;
  margin-top: 10px;
  padding-right: 20px;
  padding-left: 20px;

  p {
    font-size: 20px;
    color: #076a68;
    font-family: 'Noto Sans Bengali', sans-serif;
    font-weight: bold;
  }
`;

export const ContainerFaleConosco = styled.div`
  flex-direction: column;

  height: 20px;
`;

export const Phone = styled.div`
  display: flex;
  margin-top: 20px;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;

  span {
    font-family: 'Noto Sans Bengali';
    font-weight: bold;
    color: #076a68;
  }
  img {
    margin-right: 10px;
  }
`;

export const Email = styled.div`
  display: flex;
  margin-top: 10px;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  span {
    font-family: 'Noto Sans Bengali';
    font-weight: bold;
    color: #076a68;
  }
  img {
    margin-right: 10px;
  }
`;

export const ContainerImageFooter = styled.div`
  display: flex;
  flex-direction: column;
  width: 20%;
  height: 60px;

  h4 {
    font-family: 'Noto Sans Bengali';
    text-align: left;
    margin-left: 10px;
    margin-top: 15px;
    color: #076a68;
  }
`;

export const ContainerSocialMidia = styled.div`
  flex-direction: column;
  height: 20px;
`;

export const SocialMidia = styled.div`
  display: flex;
  margin-top: 20px;
  flex-direction: row;
  justify-content: space-evenly;
`;
