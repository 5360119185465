import styled from 'styled-components';
import imageMain from '../../assets/imageMain.svg';

export const Container = styled.div`
  height: 100vh;
  display: flex;
  align-items: stretch;

  > a {
    position: absolute;
    right: 20px;
    bottom: 20px;
    left: 90%;
  }
`;
export const Logo = styled.div`
  align-items: flex-start;
`;
export const Content = styled.div`
  display: flex;
  flex-direction: column;
  /* /* align-items: center; */
  place-content: center;
  margin-top: 20px;
  margin-left: 50px;
  width: 100%;
  max-width: 600px;

  span {
    width: 500px;
    margin-bottom: 100px;
    justify-content: center;

    /* text-align: left; */

    h1 {
      color: #2ea5a2;
      font-size: 56px;
      margin-top: 70px;
      margin-right: 10px;
    }

    h3 {
      font-size: 22px;
      color: #9ad6d4;
      text-align: left;
      /* margin-top: 24px; */
    }
  }
`;

export const ContentPage = styled.div``;
export const Background = styled.div`
  flex: 1;
  background: url(${imageMain}) no-repeat center;
  background-size: strech;
`;

// export const Header = styled.header`
//   display: flex;
//   align-items: center;
//   justify-content: space-between;
//   margin-left: 56px;
//   margin-right: 56px;
// `;

// export const Body = styled.div`
//   h1 {
//     color: #2ea5a2;
//     font-size: 56px;
//     margin-top: 136px;
//   }
// `;

// export const ItemHeaderProd = styled.h3`
//   font-size: 18px;
//   color: #3ea9a8;
// `;
// export const ItensHeader = styled.span`
//   display: flex;
//   align-items: center;
//   a {
//     & + a {
//       margin-left: 24px;
//     }
//     font-size: 18px;
//     color: #3ea9a8;
//     text-decoration: none;

//     &:active {
//       color: #004436;
//       text-decoration: underline;
//     }
//   }
// `;

// export const SubTitle = styled.h3`
//   font-size: 24px;
//   color: #9ad6d4;
//   width: 399px;
//   height: 34px;
//   margin-top: 24px;
// `;

// // export const Body = styled.span`
// //   display: flex;
// //   margin-right: 56px;
// //   margin-left: 56px;
// //   justify-content: space-between;
// //   @media (max-width: 1190px) {
// //     flex-direction: column;
// //   }
// // `;
// export const ContentPage = styled.span``;
// export const ContainerImage = styled.image`
//   margin-top: 32px;
//   max-width: 700px;
// `;
export const ButtonMore = styled.span`
  button {
    color: #ffffff;
    background: #13b5a0;
    font-size: 24px;
    width: 258px;
    height: 56px;
    margin-top: 64px;
    border-radius: 20px;
    border: 0;
    cursor: pointer;

    &:hover {
      background: #089a87;
    }
  }
`;

// export const ContactWhatsapp = styled.circle`
//   width: 64px;
//   height: 64px;
//   right: 40px;
//   bottom: 40px;

//   a {
//     position: absolute;
//     right: 40px;
//     bottom: 40px;
//     justify-content: flex-end;
//   }
// `;
